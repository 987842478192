import { cloneDeep } from 'lodash';
import MultiplePhotoSelector from '@/shared/components/multiple-photo-selector/MultiplePhotoSelector.vue';
import SpaceDetailsForm from '@/pages/vendor/space/details/forms/SpaceDetailsForm';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import ActivitiesSelector from '@/pages/vendor/space/details/ActivitiesSelector.vue';
import toJSON from '@/api/base/toJSON';
import { loadSpaceImages } from '@/shared/api-modules/vendor.api';
import getSpace from '@/api/space/getById';
import { defineComponent, ref, computed, watch, getCurrentInstance, onMounted } from '@vue/composition-api';
import useAsyncPage from '@/shared/composables/useAsyncPage';
export default defineComponent({
    components: {
        MultiplePhotoSelector,
        PageLoadingBar,
        ActivitiesSelector
    },
    props: {
        revert: {
            type: Boolean,
            default: false
        },
        onSave: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const instance = getCurrentInstance();
        const errors = ref([]);
        const form = ref(new SpaceDetailsForm());
        const formLoaded = ref({});
        const isImagesShouldReset = ref(false);
        const space = ref(null);
        const spaceDetails = computed(() => globalThis.$store.getters['$_vendor/spaceDetails']);
        const venueId = computed(() => globalThis.$store.getters['$_vendor/venueId']);
        const canContinue = computed(() => {
            const keys = form.value.requiredFields();
            const filled = Object.keys(form.value).filter(key => keys.includes(key) &&
                (Array.isArray(form.value[key])
                    ? !form.value[key].length
                    : !form.value[key]));
            return filled.length === 0;
        });
        const isSettingsChanged = computed(() => {
            if (formLoaded.value !== {}) {
                return JSON.stringify(form.value) !== JSON.stringify(formLoaded.value);
            }
            return false;
        });
        watch(() => spaceDetails.value, value => {
            if (value) {
                form.value.loadFromModel(spaceDetails.value);
                form.value.venueId = root.$route.params.venueId || venueId.value;
            }
        }, { immediate: true });
        watch(() => props.revert, () => {
            if (props.revert) {
                form.value = cloneDeep(formLoaded.value);
                isImagesShouldReset.value = true;
            }
        }, { immediate: true });
        watch(() => props.onSave, () => {
            if (props.onSave) {
                formLoaded.value = cloneDeep(form.value);
            }
        }, { immediate: true });
        watch(() => form.value, () => {
            if (formLoaded.value === {} || !form.value.id)
                return;
            emit('settingsChanged', {
                page: 'details',
                data: form.value,
                prevData: formLoaded.value,
                extra: form.value.images
            });
        }, { deep: true, immediate: true });
        const onImagesLoaded = () => {
            isImagesShouldReset.value = false;
        };
        const checkErrors = () => {
            errors.value = [];
            form.value.validate(instance);
            Object.keys(form.value).forEach(key => {
                const err = form.value.errors(key);
                if (err) {
                    errors.value.push(key);
                }
            });
            if (errors.value.length) {
                root.$scrollTo(`#${errors.value[0]}`, 1500, { offset: -100 });
            }
            return errors.value;
        };
        const { dataLoaded, callAPIAction } = useAsyncPage();
        const mainAction = async () => {
            checkErrors();
            if (!errors.value.length) {
                globalThis.$store.commit('$_vendor/SET_SPACE_DETAILS', form.value);
                globalThis.$router.push({
                    name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.CONFIGURATIONS,
                    params: { venueId: root.$route.params.venueId }
                });
            }
        };
        watch(() => form.value, () => {
            if (!form.value.name ||
                !form.value.description ||
                !form.value.activities.length ||
                form.value.images.length < 3)
                return;
            globalThis.$store.commit('$_vendor/SET_SPACE_DETAILS', form.value);
        }, { deep: true, immediate: true });
        onMounted(async () => {
            const { spaceId, venueId: _venueId } = root.$route.params;
            await callAPIAction('$_vendor/initStaticSpaceData');
            await callAPIAction('$_vendor/loadSpaceFromStorage');
            form.value.venueId = _venueId || venueId.value;
            globalThis.$store.commit('$_vendor/SET_VENUE_ID', form.value.venueId);
            if (spaceId) {
                space.value = await toJSON(getSpace(spaceId));
                space.value = {
                    ...space.value,
                    space_images: await loadSpaceImages(space.value.space_id)
                };
                form.value.loadFromAPI(space.value);
                form.value.activities = form.value.activities.map(activity => {
                    return {
                        value: true,
                        id: activity.category_id,
                        category_name: activity.category_name
                    };
                });
                formLoaded.value = cloneDeep(form.value);
            }
        });
        return {
            canContinue,
            dataLoaded,
            form,
            isImagesShouldReset,
            isSettingsChanged,
            checkErrors,
            mainAction,
            onImagesLoaded
        };
    }
});
