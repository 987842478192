import BaseForm from '@/shared/models/BaseForm';
class SpaceDetailsModel extends BaseForm {
    accessInstructions;
    activities;
    address;
    description;
    features;
    id;
    images;
    isActive;
    name;
    slug;
    venueId;
    constructor() {
        super();
        this.id = '';
        this.venueId = null;
        this.name = '';
        this.address = '';
        this.description = '';
        this.accessInstructions = '';
        this.images = [];
        this.activities = [];
        this.features = {};
        this.isActive = false;
        this.slug = '';
    }
    apiAttributes() {
        return {
            id: 'space_id',
            venueId: 'venue_id',
            name: 'internal_name',
            address: 'address',
            description: 'space_description',
            accessInstructions: 'access_instructions',
            images: 'space_images',
            activities: 'space_categories',
            isActive: 'is_active',
            slug: 'space_url'
        };
    }
}
export default SpaceDetailsModel;
