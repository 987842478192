import { defineComponent, ref, watch } from '@vue/composition-api';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
export default defineComponent({
    components: {
        FormCheckbox
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        activity: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, { emit }) {
        const checkboxValue = ref(props.value);
        watch(() => props.value, () => {
            checkboxValue.value = props.value;
        }, { immediate: true });
        watch(() => checkboxValue.value, () => {
            emit('input', checkboxValue.value);
        }, { immediate: true });
        return {
            checkboxValue
        };
    }
});
