import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
    setup(_, { emit }) {
        const auth = ref(null);
        const onAuthClick = (e) => {
            if (auth.value === e.target) {
                emit('close');
            }
        };
        const closePopup = () => {
            emit('close');
        };
        return {
            auth,
            closePopup,
            onAuthClick
        };
    }
});
