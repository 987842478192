import Vue from 'vue';
import SpaceDetailsModel from '@/shared/models/space/SpaceDetailsModel';
class SpaceDetailsForm extends SpaceDetailsModel {
    hideAttributes() {
        return ['id', 'images', 'address', 'slug'];
    }
    requiredFields() {
        return ['description', 'images', 'activities', 'name'];
    }
    _activitiesRequired(vue, form, errorCode) {
        if (!form.activities.length) {
            Vue.set(form._errors, 'activities', vue.$t(errorCode));
        }
    }
    validationRules() {
        return {
            name: [
                {
                    validation: 'required',
                    i18code: 'space.details.errors.name_empty'
                },
                {
                    validation: 'maxLength',
                    value: 100,
                    i18code: 'space.details.errors.name_too_long'
                }
            ],
            description: [
                {
                    validation: 'required',
                    i18code: 'space.details.errors.description_empty'
                },
                {
                    validation: 'maxLength',
                    value: 1000,
                    i18code: 'space.details.errors.description_too_long'
                }
            ],
            activities: [
                {
                    validation: this._activitiesRequired,
                    i18code: 'space.details.errors.activities_empty'
                }
            ],
            images: [
                {
                    validation: this._imagesRequired,
                    i18code: 'space.details.errors.images_empty'
                }
            ]
        };
    }
}
export default SpaceDetailsForm;
