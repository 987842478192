import { ref } from '@vue/composition-api';
import EventBus from '@/shared/services/eventBus';
import { EVENTBUS_EVENTS } from '@/shared/const';
export default function useAsyncPage() {
    const dataLoaded = ref(false);
    const callAPIAction = async (action, param) => {
        if (action === '$_vendor/initVendor') {
            try {
                const result = await globalThis.$store.dispatch('$_vendor/initVendor', param);
                dataLoaded.value = true;
                return result;
            }
            catch (e) {
                dataLoaded.value = true;
                if (e.status !== 404) {
                    EventBus.$emit(EVENTBUS_EVENTS.API_ERROR, e);
                }
            }
        }
        else {
            const result = await globalThis.$store.dispatch(action, param);
            dataLoaded.value = true;
            return result;
        }
    };
    return {
        dataLoaded,
        callAPIAction
    };
}
