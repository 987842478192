import get from '@/api/base/with-authorization/get';
/**
 * Fetch a space by ID.
 *
 * @param {number} id space ID.
 *
 * @returns {Promise}
 */
export default function (id) {
    return get(`space/${id}/`);
}
