import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import ModalPopup from '@/shared/components/ModalPopup.vue';
import ActivityItem from '@/pages/vendor/space/details/ActivityItem.vue';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    components: {
        ModalPopup,
        ActivityItem
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        spaceId: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { emit } = context;
        const isSelectorVisible = ref(false);
        const isActivitySelected = ref(false);
        const isMounted = ref(false);
        const activities = ref({});
        const spaceStaticCategories = computed(() => globalThis.$store.getters['$_vendor/spaceStaticCategories']);
        watch(() => spaceStaticCategories.value, () => {
            if (spaceStaticCategories.value.length) {
                spaceStaticCategories.value.forEach(a => {
                    activities.value[a.category_name] = {
                        value: false,
                        id: a.category_id,
                        category_name: a.category_name
                    };
                });
                if (spaceStaticCategories.value.length ===
                    Object.keys(activities.value).length) {
                    isMounted.value = true;
                }
                props.value.forEach(value => {
                    const activity = value.category_name;
                    activities.value[activity].value = true;
                });
            }
        }, { immediate: true });
        watch(() => isMounted.value, () => {
            props.value.forEach(value => {
                const activity = value.category_name;
                activities.value[activity].value = true;
            });
        }, { immediate: true });
        watch(() => props.value, () => {
            if (props.value && isMounted.value) {
                Object.values(activities.value).forEach(el => {
                    el.value = false;
                });
                props.value.forEach(value => {
                    const activity = value.category_name;
                    activities.value[activity].value = true;
                });
            }
        }, { immediate: true });
        const sendForm = () => {
            let arr = [];
            Object.values(activities.value).forEach(el => {
                if (el.value)
                    arr.push(el);
            });
            arr.map(el => ({
                category_id: el.id,
                category_name: el.category_name,
                has_mandatory: true
            }));
            emit('input', arr);
        };
        const removeActivity = (activity) => {
            activities.value[activity.category_name].value = false;
            sendForm();
        };
        const onSelectorInput = () => {
            isActivitySelected.value = true;
            sendForm();
        };
        const openSelector = () => {
            isSelectorVisible.value = true;
        };
        EventBus.$on('closeActivitiesModal', () => {
            if (isSelectorVisible.value === true)
                closeSelector();
        });
        const closeSelector = () => {
            isSelectorVisible.value = false;
        };
        return {
            activities,
            isActivitySelected,
            isMounted,
            isSelectorVisible,
            closeSelector,
            onSelectorInput,
            openSelector,
            removeActivity
        };
    }
});
